import { createUseStyles } from "react-jss";
import MailIcon from "../assets/email-icon.svg";
import PhoneIcon from "../assets/phone-icon.svg";
import Logo from "../assets/cactus_light_horizontal.png";
import { Link } from "react-scroll";

const useStyles = createUseStyles({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1000
  },
  headerTop: {
    backgroundColor: "#FFF",
    padding: "10px 10%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:400px)": {
      flexDirection: 'column'
    }
  },
  headerTopLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    textDecoration: "none",
  },
  headerTopLinkImg: {
    height: "20px",
    marginRight: "10px",
  },
  headerBottom: {
    backgroundColor: "#777F4A",
    padding: "10px 10%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 450px)":{
      flexDirection: 'column'
    }
  },
  headerBottomRight: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 750px)":{
      width: "50%"
    },
    "@media (max-width: 450px)":{
      width: "100%",
      marginTop: '20px'
    }
  },
  scrollLink: {
    cursor: "pointer",
    color: "white",
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.headerTop}>
        <a
          className={classes.headerTopLink}
          href="mailto:contact@cactuspigeons.com"
          style={{ marginRight: "20px" }}
        >
          <img className={classes.headerTopLinkImg} src={MailIcon} />
          <span>contact@cactuspigeons.com</span>
        </a>
        <a className={classes.headerTopLink} href="tel:4805129349">
          <img className={classes.headerTopLinkImg} src={PhoneIcon} />
          <span>480-512-9349</span>
        </a>
      </div>
      <div className={classes.headerBottom}>
        <Link
          className={classes.scrollLink}
          to="top"
          smooth={true}
          duration={500}
        >
          <img style={{ width: "150px" }} src={Logo} />
        </Link>
        <div className={classes.headerBottomRight}>
          <Link
            className={classes.scrollLink}
            to="about"
            smooth={true}
            duration={500}
          >
            ABOUT
          </Link>
          <Link
            className={classes.scrollLink}
            to="faq"
            smooth={true}
            duration={500}
          >
            FAQ
          </Link>
          <Link
            className={classes.scrollLink}
            to="contact"
            smooth={true}
            duration={500}
          >
            CONTACT
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
