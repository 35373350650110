import { Element } from "react-scroll";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import Logo from "../assets/cactus_icon_hollow.svg";
import PhoneIcon from "../assets/phone-icon-white.svg";
import EmailIcon from "../assets/email-icon-white.svg";
import AddressIcon from "../assets/address-icon.svg";

const useStyles = createUseStyles({
  container: {
    padding: "50px 20px",
    backgroundImage: "linear-gradient(to bottom, #6B7449, #D8D8C4)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    fontSize: "25px",
    margin: "0 auto 70px",
    color: "white",
    borderBottom: "2px solid white",
    maxWidth: "120px",
    paddingBottom: "10px",
  },
  secondContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    maxWidth: "1000px",
    width: "100%",
    position: "relative", // Important for positioning the left container
    boxSizing: "border-box",
    "@media (max-width: 750px)": {
      flexDirection: "column",
      padding: "20px",
    },
  },
  left: {
    position: "absolute", // Allow it to overlap the right container
    top: "20px",
    left: "0", // Align it to the left
    width: "35%", // Adjust width based on how much overlap you need
    padding: "20px",
    backgroundColor: "#6B7449",
    boxShadow: "7px 7px 10px #777",
    borderRadius: "30px",
    border: "3px solid #5C6539",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2, // Ensure it overlaps the right container
    // maxHeight: "310px",
    boxSizing: "border-box",
    "& img": {
      width: "150px",
      marginBottom: "10px",
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      color: "white",
      textDecoration: "none",
      "& img": {
        marginRight: "10px",
        width: "20px",
      },
    },
    "@media (max-width: 750px)": {
      position: "relative", // Stack vertically on small screens
      marginBottom: "20px",
      maxHeight: "none",
      width: "100%",
    },
  },
  right: {
    flex: 1,
    backgroundColor: "#F9F9F9",
    boxShadow: "7px 7px 10px #777",
    padding: "80px 20px 80px 30%",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1, // Make sure it's behind the left container
    boxSizing: "border-box",
    marginLeft: "10%", // Adjust for visual balance and overlap with the left
    "@media (max-width: 750px)": {
      padding: "20px",
      width: "100%",
      marginLeft: "0", // No margin on small screens
      marginTop: "30px",
    },
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    "@media (max-width: 470px)": {
      flexDirection: "column",
    },
  },
  input: {
    flex: 1,
    marginRight: "10px",
    padding: "10px",
    border: "1px solid #CCC",
    borderRadius: "10px",
    "&:last-child": {
      marginRight: 0,
    },
    "@media (max-width: 470px)": {
      marginBottom: "20px",
    },
  },
  textarea: {
    width: "100%",
    padding: "10px",
    border: "1px solid #CCC",
    borderRadius: "10px",
    minHeight: "100px",
    marginBottom: "15px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#6B7449",
    color: "#FFF",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#5C6539",
    },
  },
});

const Contact = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:contact@cactuspigeons.com?subject=Contact Form Submission&body=First Name: ${firstName}%0D%0ALast Name: ${lastName}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0AMessage: ${encodeURIComponent(
      message
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <Element name="contact" className={classes.container}>
      <h2 className={classes.header}>CONTACT</h2>
      <div className={classes.secondContainer}>
        <div className={classes.left}>
          <img src={Logo} alt="Logo" />
          <div>
            <a href="tel:4805129349">
              <img src={PhoneIcon} alt="Phone" />
              480-512-9349
            </a>
            <a href="mailto:contact@cactuspigeons.com">
              <img src={EmailIcon} alt="Email" />
              contact@cactuspigeons.com
            </a>
            <a href="https://maps.app.goo.gl/igdJQK9ufpvHgzGE8">
              <img src={AddressIcon} alt="Address" />
              1846 E Innovation Park Dr, Oro Valley, AZ 85755
            </a>
          </div>
        </div>
        <div className={classes.right}>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputGroup}>
              <input
                type="text"
                className={classes.input}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                className={classes.input}
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                type="email"
                className={classes.input}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="tel"
                className={classes.input}
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <textarea
              className={classes.textarea}
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit" className={classes.button}>
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </Element>
  );
};

export default Contact;
