import React, { useState, useEffect, useRef } from "react";
import HeroLeft from "../assets/hero-left.png";
import HeroRight from "../assets/hero-right.png";
import HeroCenter from "../assets/hero-center.png";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  right: {
    width: "100%",
    maxWidth: "600px",
    position: "absolute",
    borderRadius: "40px",
    right: 0,
    marginTop: "40px",
    boxShadow: "10px 1px 10px #777",
    "@media (max-width: 700px)": {
      display: "none",
    },
  },
  left: {
    width: "100%",
    maxWidth: "600px",
    position: "absolute",
    borderRadius: "40px",
    marginTop: "40px",
    left: 0,
    boxShadow: "10px 1px 10px #777",
    "@media (max-width: 700px)": {
      display: "none",
    },
  },
  center: {
    width: "100%",
    maxWidth: "700px",
    position: "absolute",
    borderRadius: "40px",
    zIndex: 10,
    boxShadow: "10px 1px 10px #777",
  },
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
    backgroundColor: "#E6FAE7",
    transition: "height 0.3s ease", // Smooth transition when the height changes
  },
});

const Hero = () => {
  const classes = useStyles();
  const [containerHeight, setContainerHeight] = useState(450); // Initial height as fallback
  const centerImgRef = useRef(null);

  // Update the container height based on the image's natural height
  useEffect(() => {
    const updateHeight = () => {
      if (centerImgRef.current) {
        setContainerHeight(centerImgRef.current.offsetHeight);
      }
    };

    // Update height when the image is loaded
    const img = centerImgRef.current;
    if (img && img.complete) {
      updateHeight();
    } else {
      img.onload = updateHeight;
    }

    // Update height on window resize to keep it responsive
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <section
      className={classes.container}
      style={{ height: `${containerHeight}px` }}
    >
      <img className={classes.left} src={HeroLeft} alt="Left Hero" />
      <img
        className={classes.center}
        src={HeroCenter}
        ref={centerImgRef}
        alt="Center Hero"
      />
      <img className={classes.right} src={HeroRight} alt="Right Hero" />
    </section>
  );
};

export default Hero;
