import React from "react";
import "./App.css";
import { Element } from "react-scroll";

import Header from "./components/header";
import Hero from "./components/hero";
import About from "./components/about";
import FAQ from "./components/faq";
import Contact from "./components/contact";

{
  /* <Link to="about" smooth={true} duration={500} offset={-80}>
About
</Link> */
}

{
  /* <Element name="faq" className="App-section">

</Element> */
}

function App() {
  return (
    <div className="App">
      <Element name="top" />
      <Header />
      <Hero />
      <About />
      <FAQ />
      <Contact />
    </div>
  );
}

export default App;
