import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Element } from "react-scroll";

const questions = [
  {
    question: "How long does the extermination process take?",
    answer:
      "The process typically takes between 1-2 hours, depending on the number of birds and the size of the property. Each case is unique, but we aim to resolve the issue as efficiently as possible.",
  },
  {
    question: "What happens to the birds after they are exterminated?",
    answer:
      "After the extermination, we remove the birds from the property and dispose of them in accordance with local regulations. If requested, we can also offer clean-up services for nests and droppings.",
  },
  {
    question:
      "Is there any risk to people or pets during the extermination process?",
    answer:
      "Safety is our top priority. We ensure that all residents and pets are kept away from the area during the extermination process, and we only perform the service in environments where it is completely safe to do so.",
  },
  {
    question:
      "How do you ensure no other animals are harmed during the extermination?",
    answer:
      "We conduct thorough assessments before extermination to identify and target only the invasive bird species. Our technicians are trained to avoid harm to non-target animals, including pets and local wildlife.",
  },
  {
    question: "How much does pigeon extermination cost?",
    answer:
      "Our standard rate for an extermination service starts at $250 for the initial 2-hour visit, with additional charges for longer durations or follow-up services. We also offer subscription packages for ongoing services.",
  },
  {
    question: "What areas do you service?",
    answer:
      "We operate throughout Arizona and service both residential and commercial properties. Contact us to confirm if we service your location.",
  },
  {
    question: "Do you provide clean-up services for bird droppings and nests?",
    answer:
      "Yes, we offer optional clean-up services to remove nests, droppings, and other debris left by the birds. This is important to prevent health risks and property damage.",
  },
  {
    question:
      "What can I do to prevent pigeons from returning after extermination?",
    answer:
      "We provide recommendations on physical deterrents like bird spikes or netting to prevent pigeons from roosting on your property again. However, our team is available for follow-up visits if needed.",
  },
  {
    question: "What if there are pigeons present again after your visit?",
    answer:
      "If you are a subscriber, we will conduct follow-up extermination services at no extra cost. For non-subscribers, we recommend booking an ad-hoc service for additional treatment.",
  },
];

const useStyles = createUseStyles({
  container: {
    position: "relative",
    backgroundColor: "#FFF",
    textAlign: "center",
    padding: "100px 0",
    color: "#000",
    overflow: "hidden",
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  triangleTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "150px",
    backgroundColor: "#6B7449",
    zIndex: -1,
    clipPath: "polygon(0px 0px, 100% 0px, 17.00% 24.00%, 0px 0%)",
  },
  triangleBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "150px",
    backgroundColor: "#6B7449",
    zIndex: -1,
    clipPath: "polygon(0px 100%, 79.01% 114px, 100% 100%, 0px 100%)",
  },
  content: {
    padding: "40px",
    backgroundColor: "#FFF",
    position: "relative",
    zIndex: 1,
    maxWidth: "800px",
    width: "100%", // Make sure the width remains the same
  },
  faqItem: {
    backgroundColor: "#6B7449",
    color: "#FFF",
    borderRadius: "25px",
    margin: "10px 0",
    padding: "15px",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#5C6539",
    },
  },
  faqQuestion: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
  },
  faqAnswer: {
    maxHeight: "0", // Collapse state
    overflow: "hidden",
    transition: "max-height 0.3s ease, opacity 0.3s ease", // Smooth transition
    opacity: 0,
    padding: "0px 15px", // Consistent padding
  },
  faqAnswerExpanded: {
    maxHeight: "500px", // Large enough to contain the content
    opacity: 1,
    padding: "10px 15px", // Keep the same padding for both states
    backgroundColor: "#FFF",
    color: "#000",
    borderRadius: "0 0 15px 15px",
    marginTop: "10px",
  },
  arrow: {
    transform: "rotate(0deg)",
    transition: "transform 0.3s",
  },
  arrowExpanded: {
    transform: "rotate(180deg)",
  },
  header: {
    fontSize: "25px",
    paddingBottom: "10px",
    borderBottom: "2px solid #777F4A",
    maxWidth: "50px",
    margin: "0 auto 30px",
  },
});

const FAQ = () => {
  const classes = useStyles();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Element name="faq" className={classes.container}>
      <div className={classes.triangleTop} />
      <div className={classes.triangleBottom} />
      <div className={classes.content}>
        <h2 className={classes.header}>FAQ</h2>
        {questions.map((item, index) => (
          <div
            key={index}
            className={classes.faqItem}
            onClick={() => toggleFAQ(index)}
          >
            <div className={classes.faqQuestion}>
              {item.question}
              <span
                className={
                  expandedIndex === index
                    ? `${classes.arrow} ${classes.arrowExpanded}`
                    : classes.arrow
                }
              >
                ▼
              </span>
            </div>
            <div
              className={
                expandedIndex === index
                  ? classes.faqAnswerExpanded
                  : classes.faqAnswer
              }
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </Element>
  );
};

export default FAQ;
