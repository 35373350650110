import { Element } from "react-scroll";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "75px 0",
  },
  header: {
    fontSize: "25px",
    paddingBottom: "10px",
    borderBottom: "2px solid #777F4A",
    marginBottom: "30px",
  },
  paragraphContainer: {
    position: "relative",
    boxShadow: "10px 1px 10px #777",
    width: "80%",
    maxWidth: "1000px",
    backgroundColor: "#A98055",
    color: "#FFF",
    lineHeight: "24px",
    textAlign: "center",
    padding: "20px 10%",
    fontSize: "18px",
    overflow: "hidden",
  },
  box1: {},
  box2: {},
  box3: {},
  topLeftCircle1: {
    position: "absolute",
    backgroundColor: "#FFF",
    height: "80px",
    width: "80px",
    top: 0,
    left: 0,
    opacity: 0.3,
    borderRadius: "0 0 100% 0",
  },
  topLeftCircle2: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.3,
    borderRadius: "0 0 100% 0",
    width: "120px",
    height: "120px",
    borderRight: "15px solid #FFF",
    borderBottom: "15px solid #FFF",
  },
  topLeftCircle3: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.3,
    borderRadius: "0 0 100% 0",
    width: "150px",
    height: "150px",
    borderRight: "5px solid #FFF",
    borderBottom: "5px solid #FFF",
  },
  bottomRightCircle1: {
    position: "absolute",
    backgroundColor: "#FFF",
    height: "80px",
    width: "80px",
    bottom: 0,
    right: 0,
    opacity: 0.3,
    borderRadius: "100% 0 0 0",
  },
  bottomRightCircle2: {
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0.3,
    borderRadius: "100% 0 0 0",
    width: "120px",
    height: "120px",
    borderLeft: "15px solid #FFF",
    borderTop: "15px solid #FFF",
  },
  bottomRightCircle3: {
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0.3,
    borderRadius: "100% 0 0 0",
    width: "150px",
    height: "150px",
    borderLeft: "5px solid #FFF",
    borderTop: "5px solid #FFF",
  },
});

const About = () => {
  const classes = useStyles();
  return (
    <Element name="about" className={classes.container}>
      <h2 className={classes.header}>ABOUT US</h2>
      <div className={classes.paragraphContainer}>
        <div className={classes.topLeftCircle1} />
        <div className={classes.topLeftCircle2} />
        <div className={classes.topLeftCircle3} />
        <p>
          At Cactus Pigeon Control, we are a small, Arizona-based business
          dedicated to preserving the local environment by humanely
          exterminating invasive bird species like pigeons. Our mission is to
          protect homes, businesses, and public spaces from the damage caused by
          these birds while ensuring the preservation of native ecosystems. With
          a commitment to cutting-edge technology and personalized service, we
          provide effective, reliable solutions tailored to each client's needs.
          As a family-run operation, we take pride in offering friendly,
          professional service that benefits both our customers and the
          environment.
        </p>
        <div className={classes.bottomRightCircle1} />
        <div className={classes.bottomRightCircle2} />
        <div className={classes.bottomRightCircle3} />
      </div>
      <div className={classes.box1} />
      <div className={classes.box2} />
      <div className={classes.box3} />
    </Element>
  );
};

export default About;
